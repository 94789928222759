<template>
    <div>
        <!-- Page content -->
        <b-container class="pt-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0">
                        <b-card-header class="bg-transparent pb-5">
                            <div class="text-center">
                                <img src="@/assets/img/logo.png" class="navbar-brand-img" alt="...">
                            </div>
                        </b-card-header>
                        <b-card-body class="px-lg-5 py-lg-5" v-loading="loading">
                            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                    <base-input alternative class="mb-3" name="email" :nameAs="$t('profile.email')" :rules="{required: true, email: true}" prepend-icon="fas fa-envelope" :placeholder="$t('profile.email')" v-model="model.email">
                                    </base-input>

                                    <div class="password-div">
                                        <base-input alternative class="mb-3" name="password" :nameAs="$t('profile.password')" :rules="{required: true, min: 6}" prepend-icon="fas fa-unlock-alt" :type="showPassword?'text':'password'" :placeholder="$t('profile.password')" v-model="model.password">
                                        </base-input>
                                        <div class="showPassword" @click="showPass">
                                            <span v-if="!showPassword">
                                                <i class="fas fa-eye"></i>
                                            </span>
                                            <span v-else>
                                                <i class="fas fa-eye-slash"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <base-button type="primary" native-type="submit" class="my-4">{{$t('general.login')}}</base-button>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { AUTH_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
import { setCookie } from '@/utils/cookie';

export default {
    data() {
        return {
            loading: false,
            model: {
                email: '',
                password: '',
                type: 'admin'
            },
            showPassword: false
        };
    },
    methods: {
        showPass() {
            this.showPassword = this.showPassword !== true;
        },
        onSubmit() {
            this.loading = true;
            this.$store
                .dispatch(AUTH_REQUEST, this.model)
                .then(resp => {
                    this.$router.push('/');
                })
                .catch(err => {
                    this.loading = false;
                    handleError(this, err);
                });
        }
    },
    mounted() {
        setCookie('user_login_type', 'admin', '1y');
    }
};
</script>
